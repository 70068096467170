import { OrderBy } from 'graph/types';
import React, { createContext, useState, useContext } from 'react';
import { ContextProps, Filter } from './types';
import localStore from 'store2';
import { useDecision } from '@optimizely/react-sdk';
import { FILTER_DEFAULT_OPEN } from 'utils/optimizelyConstants';

export const DEFAULT_SORTING = OrderBy.ReferralDateDesc;
export const DEFAULT_PAGE_NUMBER = 1;
export const storeSortKey = 'sortOrder';
export const storeFilterKey = 'filter';
const DEFAULT_FILTER = [{ applicationStatus: 'open' }];

export const ReportContext = createContext<ContextProps>({
  sortOrder: DEFAULT_SORTING,
  setSortOrder: () => undefined,
  filter: DEFAULT_FILTER,
  setFilter: () => undefined,
  pageTokens: [''],
  setPageTokens: () => undefined,
  pageNumber: DEFAULT_PAGE_NUMBER,
  setPageNumber: () => undefined,
});

ReportContext.displayName = 'ReportContext';

export const ReportProvider: React.FunctionComponent = ({ children }) => {
  const [sortOrder, setSortOrder] = useState<OrderBy>(
    localStore.get(storeSortKey) || DEFAULT_SORTING
  );

  const [filterDefaultOpen, optimizelyClientReady] = useDecision(FILTER_DEFAULT_OPEN);
  const filterDefaultOpenEnabled = filterDefaultOpen.enabled && optimizelyClientReady;
  const initialFilter = filterDefaultOpenEnabled ? DEFAULT_FILTER : [];
  const [filter, setFilter] = useState<Filter[]>(localStore.get(storeFilterKey) || initialFilter);

  const setSortWithLocalStore = (order: OrderBy) => {
    localStore.set(storeSortKey, order);
    setSortOrder(order);
  };

  const setFilterWithLocalStore = (value: Filter[]) => {
    localStore.set(storeFilterKey, value);
    setFilter(value);
  };

  const [pageTokens, setPageTokens] = useState(['']);
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);

  return (
    <ReportContext.Provider
      value={{
        sortOrder,
        setSortOrder: setSortWithLocalStore,
        filter,
        setFilter: setFilterWithLocalStore,
        pageTokens,
        setPageTokens,
        pageNumber,
        setPageNumber,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};

export const useReportContext = (): ContextProps => useContext(ReportContext);

export default ReportProvider;

import React, { useEffect, useState } from 'react';
import { Col, LoadingIndicator } from '@policygenius/mortar';
import { Form } from 'react-final-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFormContext } from 'context/FormContext';
import DropTicketForm from './form';
import { getValidator } from './form/utils/validate';
import { LifeApplicationInfo } from 'context/FormContext/types';
import { useUserLifeAppMutation } from 'mutation/user/mutation';
import { useToastContext } from 'context/ToastContext';
import { Events, track } from 'utils/analytics';
import { userHasSelectedPolicy, moneyToString } from 'utils/helpers';
import { PaginatedReportsDocument } from 'query/statusReportsPagination/query';
import { FormContainer, Subtitle, Title } from './styles';
import { Background } from 'components/Background';
import { TOAST_TEXT } from './constants';
import { CreationMethod } from 'graph/types';
import { useCurrentUserQuery } from 'query/currentUser/query';
import { REPORTS_ROUTE } from 'routes/constants';

const DropTicket: React.FC = () => {
  const navigate = useNavigate();
  const { data: user } = useCurrentUserQuery();
  const { state, dispatch } = useFormContext();

  const { show } = useToastContext();

  const [userLifeAppMutation, { data }] = useUserLifeAppMutation({
    onError: (e) => {
      if (e.message.includes('Cannot submit business in NY')) {
        show({
          type: 'error',
          title: TOAST_TEXT.errorNYCarrier.title,
          body: TOAST_TEXT.errorNYCarrier.body,
        });
      } else {
        show({
          type: 'error',
          title: TOAST_TEXT.error.title,
          body: TOAST_TEXT.error.body,
        });
      }
    },
    onCompleted: () => {
      if (user?.currentUser.tcpaRequired) {
        navigate(`${REPORTS_ROUTE}/${data?.userLifeApp.applicationResponse?.referenceId}`);
        show({
          type: 'success',
          title: TOAST_TEXT.success.title,
          body: TOAST_TEXT.success.body,
        });
      } else {
        navigate('/schedule', {
          state: {
            referenceId: data?.userLifeApp.applicationResponse?.referenceId,
          },
        });
      }
    },
    refetchQueries: [{ query: PaginatedReportsDocument }],
  });

  const onSubmit = async (values: LifeApplicationInfo) => {
    dispatch({ type: 'UPDATE_CLIENT', payload: values });
    track(Events.SUBMITTED_BUSINESS, {
      clientEmail: values.email,
      selectedPolicy: userHasSelectedPolicy(state),
    });
    await userLifeAppMutation({
      variables: {
        gender: values.gender!,
        dateOfBirth: values.dateOfBirth!,
        state: values.state!,
        tobaccoUse: state.tobacco,
        firstName: values.firstName!,
        lastName: values.lastName!,
        phone: values.phone!,
        email: values.email!,
        coverageAmount: values.coverageAmount ? Number(moneyToString(values.coverageAmount)) : null,
        termInYears: values.term ? Number(values.term) : null,
        notes: values.notes,
        paymentMode: state.paymentMode?.toLocaleLowerCase(),
        referringAgent: values.referringAgent?.uuid,
        creationMethod: CreationMethod.Advisor,
        ...(userHasSelectedPolicy(state) && {
          policyPreference: {
            annualPremium: state.policySelection.annualPremium!,
            carrier: state.policySelection.carrier!,
            carrierName: state.policySelection.carrierName!,
            healthCategory: state.policySelection.healthCategory!,
            isTobacco: state.policySelection.isTobacco!,
            issueAge: state.policySelection.issueAge!,
            issueType: state.policySelection.issueType!,
            monthlyPremium: state.policySelection.monthlyPremium!,
            name: state.policySelection.planName!,
            planIdentifierName: state.policySelection.planIdentifierName!,
            productType: state.policySelection.productType!,
            returnOfPremium: state.policySelection.returnOfPremium!,
            tableRating: state.policySelection.tableRating!,
            underwritingClass: state.policySelection.underwritingClass!,
            useNearestAge: state.policySelection.useNearestAge!,
          },
        }),
      },
    });
  };

  const location = useLocation();
  const [stateUpdating, setStateUpdating] = useState(true);

  useEffect(() => {
    if (!location.state?.keepClientState) {
      dispatch({
        type: 'RESET_STATE',
      });
    }
    setStateUpdating(false);
  }, []);

  return (
    <Background>
      <FormContainer>
        {stateUpdating ? (
          <Col>
            <LoadingIndicator color="gray500" />
          </Col>
        ) : (
          <Col push={{ medium: 1 }} span={{ small: 4, medium: 10, large: 7 }}>
            <Title
              size={{
                medium: 'xlarge',
                small: 'large',
              }}
            >
              Create a drop ticket
            </Title>
            <Subtitle
              size={{
                medium: 'large',
                small: 'medium',
              }}
            >
              Send Policygenius your client’s information and we’ll do the rest.
            </Subtitle>
            <Form onSubmit={onSubmit} initialValues={state} validate={getValidator}>
              {(formProps) => <DropTicketForm {...formProps} />}
            </Form>
          </Col>
        )}
      </FormContainer>
    </Background>
  );
};

export default DropTicket;

import React from 'react';
import { AlertOpportunity, Link, UtilityText, theme } from '@policygenius/mortar';
import { useFormContext } from 'context/FormContext';
import { UNIVERSAL_BADGES } from 'utils/optimizelyConstants';
import {
  Accelerated,
  BadgeContainer,
  CardButtonsContainer,
  CarrierLink,
  CarrierProductHealthPrice,
  DesktopCardButtonsContainer,
  EappBanner,
  Instant,
  Label,
  MobileBadgeContainer,
  MobilePrice,
  NonMed,
  ProductCardContainer,
  ProductHealthContainer,
  ProductHealthSection,
  Simplified,
  TabletUpPremium,
  TitlePrice,
} from './styles';
import { useNavigate } from 'react-router-dom';
import Premium from '../Premium';
import { carrierLinks } from 'pages/Quotes/utils';
import { DROP_TICKET_ROUTE, EAPP_ROUTE } from 'routes/constants';
import Button from 'components/Button';
import { Props } from './types';
import { useDecision } from '@optimizely/react-sdk';

const EAPP_CARRIER_PRODUCTS = ['trendsetter super', 'trendsetter lb'];
const EAPP_CARRIERS = ['transamerica'];

/* eslint-disable complexity */
const Card: React.FC<Props> = ({ eappEnabled, isBOR, policy }) => {
  const {
    carrierName,
    monthlyPremium,
    annualPremium,
    planIdentifierName,
    healthCategory,
    name,
    carrier,
    ...rest
  } = policy;

  const { state, dispatch } = useFormContext();
  const navigate = useNavigate();

  const [universalBadges, optimizelyClientReady] = useDecision(UNIVERSAL_BADGES);

  const univesalBadgesEnabled = universalBadges.enabled && optimizelyClientReady;

  const acceleratedBadge = policy.attributes.includes('accelerated');
  const nonmMedBadge = policy.attributes.includes('non-med');
  const simplifiedBadge = policy.attributes.includes('simplified');
  const instantBadge = policy.attributes.includes('instant');

  const hasBadge = univesalBadgesEnabled
    ? policy.attributes.length > 0
    : rest.issueType === 'accelerated_underwriting' || rest.issueType === 'simplified';

  const monthly = state.paymentMode === 'Monthly';

  const premiumAmount = monthly ? monthlyPremium : annualPremium;

  const frequency = monthly ? '/mo' : '/yr';

  const carrierLink = carrierLinks[carrierName as keyof typeof carrierLinks];

  const isEappProductAndCarrier =
    EAPP_CARRIER_PRODUCTS.includes(planIdentifierName.toLowerCase()) &&
    EAPP_CARRIERS.includes(carrier.toLowerCase());

  const isEappEligible = eappEnabled && isEappProductAndCarrier && isBOR;

  const saveSelection = (isEapp: boolean) => {
    dispatch({
      type: 'SELECT_POLICY',
      payload: {
        carrierLink,
        annualPremium,
        carrierName,
        monthlyPremium,
        planName: name,
        planIdentifierName,
        healthCategory,
        carrier,
        ...rest,
      },
    });

    if (isEapp) {
      navigate(EAPP_ROUTE);
    } else {
      navigate(DROP_TICKET_ROUTE, { state: { keepClientState: true } });
    }
  };

  return (
    <>
      {isEappEligible && (
        <EappBanner>
          <AlertOpportunity color="green400" />
          <UtilityText weight="bold" size="small" color="green">
            eApp available — no phone call required
          </UtilityText>
        </EappBanner>
      )}
      <ProductCardContainer hasBadge={hasBadge} isEapp={isEappEligible}>
        <CarrierProductHealthPrice>
          <TitlePrice>
            <div>
              <Link
                href={carrierLink}
                variant="secondary"
                target="_blank"
                rel="noreferrer"
                color="inherit"
              >
                <CarrierLink weight="bold" size="large" text-decoration-line="underline">
                  {carrierName}
                </CarrierLink>
              </Link>
              <BadgeContainer>
                {((univesalBadgesEnabled && acceleratedBadge) ||
                  (!univesalBadgesEnabled && rest.issueType === 'accelerated_underwriting')) && (
                  <Accelerated mobile={false} />
                )}
                {((univesalBadgesEnabled && instantBadge) ||
                  (!univesalBadgesEnabled && rest.issueType === 'instant')) && (
                  <Instant mobile={false} />
                )}
                {((univesalBadgesEnabled && simplifiedBadge) ||
                  (!univesalBadgesEnabled && rest.issueType === 'simplified')) && (
                  <Simplified mobile={false} />
                )}
                {((univesalBadgesEnabled && nonmMedBadge) ||
                  (!univesalBadgesEnabled && rest.issueType === 'non-med')) && (
                  <NonMed mobile={false} />
                )}
              </BadgeContainer>
            </div>
            <MobilePrice>
              <Premium premiumAmount={premiumAmount} frequency={frequency} />
            </MobilePrice>
          </TitlePrice>
          <BadgeContainer>
            {((univesalBadgesEnabled && acceleratedBadge) ||
              (!univesalBadgesEnabled && rest.issueType === 'accelerated_underwriting')) && (
              <MobileBadgeContainer>
                <Accelerated mobile />
              </MobileBadgeContainer>
            )}
            {((univesalBadgesEnabled && instantBadge) ||
              (!univesalBadgesEnabled && rest.issueType === 'instant')) && (
              <MobileBadgeContainer>
                <Instant mobile />
              </MobileBadgeContainer>
            )}
            {((univesalBadgesEnabled && simplifiedBadge) ||
              (!univesalBadgesEnabled && rest.issueType === 'simplified')) && (
              <MobileBadgeContainer>
                <Simplified mobile />
              </MobileBadgeContainer>
            )}
            {((univesalBadgesEnabled && nonmMedBadge) ||
              (!univesalBadgesEnabled && rest.issueType === 'non-med')) && (
              <MobileBadgeContainer>
                <NonMed mobile />
              </MobileBadgeContainer>
            )}
          </BadgeContainer>
          <ProductHealthContainer>
            <ProductHealthSection>
              <Label>Product name</Label>
              <UtilityText size="small">{planIdentifierName}</UtilityText>
            </ProductHealthSection>
            <ProductHealthSection>
              <Label>Health class</Label>
              <UtilityText size="small">{healthCategory}</UtilityText>
            </ProductHealthSection>
          </ProductHealthContainer>
          <TabletUpPremium data-testid="priceAmount">
            <Premium premiumAmount={premiumAmount} frequency={frequency} />
          </TabletUpPremium>
          <DesktopCardButtonsContainer renderEapp={isEappEligible}>
            {isEappEligible && (
              <Button
                variant="filled"
                analyticsText="eApp"
                onClick={() => saveSelection(true)}
                fullWidth={window.innerWidth < theme.breakpoints.medium}
              >
                <span>eApp</span>
              </Button>
            )}
            <Button
              variant="filled"
              analyticsText="Select"
              onClick={() => saveSelection(false)}
              fullWidth={window.innerWidth < theme.breakpoints.medium}
            >
              Drop ticket
            </Button>
          </DesktopCardButtonsContainer>
        </CarrierProductHealthPrice>
        <CardButtonsContainer renderEapp={isEappEligible}>
          {isEappEligible && (
            <Button
              variant="filled"
              analyticsText="eApp"
              onClick={() => saveSelection(true)}
              fullWidth={window.innerWidth < theme.breakpoints.medium}
            >
              <AlertOpportunity />
              <span>eApp</span>
            </Button>
          )}
          <Button
            variant="filled"
            analyticsText="Select"
            onClick={() => saveSelection(false)}
            fullWidth={window.innerWidth < theme.breakpoints.medium}
          >
            Drop ticket
          </Button>
        </CardButtonsContainer>
      </ProductCardContainer>
    </>
  );
};

export default Card;

import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { LoadingIndicator, MortarProvider } from '@policygenius/mortar';
import { Loader, LoadingNav } from './styles';
import { matchPath, useLocation } from 'react-router-dom';
import { REFERRAL_ROUTE } from 'routes/constants';
import PgProLogo from 'components/PgIcons/PgProLogo';

const Authenticator: React.FC = ({ children }) => {
  const { pathname } = useLocation();

  const referralPage = !!matchPath(
    {
      path: REFERRAL_ROUTE,
      end: true,
    },
    pathname
  );

  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

  const shouldLogin = !isAuthenticated && !isLoading && !referralPage;
  const showLoader = (isLoading || !isAuthenticated) && !referralPage;

  const params = new URLSearchParams(document.location.search);
  const invitation = params.get('invitation');
  const organization = params.get('organization');

  if (shouldLogin) {
    if (invitation && organization) {
      loginWithRedirect({
        authorizationParams: {
          organization,
          invitation,
          /* eslint-disable-next-line camelcase */
          screen_hint: 'signup',
        },
      });
    } else {
      loginWithRedirect({ appState: { returnTo: window.location.pathname } });
    }
  }

  return (
    <>
      {showLoader ? (
        <>
          <MortarProvider>
            <LoadingNav>
              <PgProLogo policygeniusColor={'black'} />
            </LoadingNav>
            <Loader data-testId="loader">
              <LoadingIndicator color={'black'} />
            </Loader>
          </MortarProvider>
        </>
      ) : (
        children
      )}
    </>
  );
};

export default Authenticator;

import React from 'react';
import { LoadingIndicator, Textbox, UtilityText } from '@policygenius/mortar';
import {
  Container,
  Background,
  Row,
  LabelContainer,
  FormContainer,
  Spacer,
  Title,
  Continue,
} from './styles';
import PgProLogo from 'components/PgIcons/PgProLogo';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { useAuth0 } from '@auth0/auth0-react';
import { INDEX_ROUTE } from 'routes/constants';
import { useCreateAgentMutation } from 'mutation/createAgent/mutation';

interface FormValues {
  firstName: string;
  lastName: string;
}

export const SelfSignup: React.FC = () => {
  const { user } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();

  const [createAgentMutation] = useCreateAgentMutation({
    onError: (e) => console.error('ERROR saving new user', e),
    onCompleted: () => {
      navigate(INDEX_ROUTE);
    },
  });

  const onSubmit = async (values: FormValues) => {
    await createAgentMutation({
      variables: {
        firstName: values.firstName,
        lastName: values.lastName,
      },
    });
  };

  const validate = (values: FormValues) => {
    const errors: Partial<FormValues> = {};

    if (!values.firstName) {
      errors.firstName = 'Required';
    }
    if (!values.lastName) {
      errors.lastName = 'Required';
    }

    return errors;
  };

  return (
    <Container>
      <Background>
        <Row>
          <LabelContainer>
            <PgProLogo />
          </LabelContainer>
          <Title size="large">Join {location.state?.orgName} on Policygenius Pro</Title>
          <Form onSubmit={onSubmit} validate={validate} initialValues={{ email: user?.email }}>
            {(formProps) => (
              <form onSubmit={formProps.handleSubmit}>
                <FormContainer>
                  <Field name="firstName" style={{ marginBottom: '16px' }}>
                    {({ input, meta }) => (
                      <Spacer>
                        <Textbox
                          {...input}
                          id="first-name"
                          name="first-name"
                          label="First name"
                          placeholder="Enter"
                          value={input.value}
                          defaultValue=""
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            input.onChange(e.target.value);
                          }}
                          type="text"
                          errorMessage={meta.touched && !meta.active ? meta.error : undefined}
                        />
                      </Spacer>
                    )}
                  </Field>
                  <Field name="lastName">
                    {({ input, meta }) => (
                      <Spacer>
                        <Textbox
                          {...input}
                          id="last-name"
                          name="last-name"
                          label="Last name"
                          placeholder="Enter"
                          value={input.value}
                          defaultValue=""
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            input.onChange(e.target.value);
                          }}
                          type="text"
                          errorMessage={meta.touched && !meta.active ? meta.error : undefined}
                        />
                      </Spacer>
                    )}
                  </Field>
                  <Field name="email">
                    {({ input }) => (
                      <Spacer large>
                        <Textbox
                          {...input}
                          id="email"
                          name="email"
                          label="Email"
                          placeholder="Enter"
                          value={input.value}
                          defaultValue=""
                          disabled
                          type="email"
                        />
                      </Spacer>
                    )}
                  </Field>
                  <Continue
                    fullWidth={false}
                    type="submit"
                    disabled={formProps.submitting || formProps.pristine || !formProps.valid}
                  >
                    {!formProps.submitting && 'Continue'}
                    {formProps.submitting && <LoadingIndicator color="gray400" />}
                  </Continue>
                  <UtilityText size="small">
                    Need help? Contact us at pgproaccounts@policygenius.com
                  </UtilityText>
                </FormContainer>
              </form>
            )}
          </Form>
        </Row>
      </Background>
    </Container>
  );
};

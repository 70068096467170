import React from 'react';
import { AccessText, TimeoutTitleText, PlaceholderBox } from './styles';
import { Events, track } from 'utils/analytics';
import { useGenerateReportMutation } from 'mutation/generateReport/mutation';
import { useToastContext } from 'context/ToastContext';
import { useDecision } from '@optimizely/react-sdk';
import { FILTER_DEFAULT_OPEN } from 'utils/optimizelyConstants';

const StatusTrackerTimeout: React.FC = () => {
  const { show, hide } = useToastContext();
  const [generateReport] = useGenerateReportMutation({
    onError: () => {
      show({
        type: 'error',
        title: 'Report failed to generate',
        body: 'Please wait a few minutes and try again.',
      });
      setTimeout(hide, 4000);
    },
    onCompleted: () => {
      show({
        type: 'success',
        title: 'Preparing csv for delivery',
        body: 'A csv file of this entire case report is being sent to your email. Depending on the number of cases, this could take several minutes.',
      });
      setTimeout(hide, 4000);
    },
  });
  const emailCsvOnClick = () => {
    track(Events.CLICKED_BUTTON, {
      analyticsText: 'Email CSV',
    });

    generateReport();
  };

  const [filterDefaultOpen, optimizelyClientReady] = useDecision(FILTER_DEFAULT_OPEN);
  const filterDefaultOpenEnabled = filterDefaultOpen.enabled && optimizelyClientReady;
  const messageStart = filterDefaultOpenEnabled
    ? 'Try adding filters to reduce the number of results or'
    : 'Sorry about that. While our team is working on a fix, you can';
  const messageEnd = filterDefaultOpenEnabled ? 'of the entire report.' : 'of the case report.';

  return (
    <PlaceholderBox>
      <TimeoutTitleText size="small">Unable to load case report</TimeoutTitleText>
      <AccessText size="small">
        {messageStart}
        <span style={{ cursor: 'pointer' }} onClick={emailCsvOnClick}>
          <u> email yourself a CSV </u>
        </span>
        {messageEnd}
      </AccessText>
    </PlaceholderBox>
  );
};

export default StatusTrackerTimeout;

import { theme, TitleText, UtilityText } from '@policygenius/mortar';
import styled from 'styled-components';

interface StyleOps {
  active?: boolean;
}

export const ToastContainer = styled.div<StyleOps>`
  z-index: 2;
  position: fixed;
  bottom: ${(props) => (props.active ? 0 : -200)}px;
  transition: bottom 300ms;
  text-align: center;
  visibility: ${(props) => (props.active ? 'initial' : 'hidden')};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (min-width: ${theme.breakpoints.medium}px) {
    bottom: ${(props) => (props.active ? 100 : -200)}px;
  }
`;

export const BodyContainer = styled.div`
  width: 100%;
  background: ${theme.colors.white};
  padding: 24px;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.12);
  border-radius: 2px;

  @media (min-width: ${theme.breakpoints.medium}px) {
    width: 558px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const TitleSubContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled(TitleText)`
  margin-left: 8px;
`;

export const ReactNodeBody = styled.div`
  text-align: left;
`;

export const Body = styled(UtilityText)`
  text-align: left;
`;

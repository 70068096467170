import React from 'react';
import {
  DropTicketErrorMessage,
  DropTicketErrorMessageNY,
  EAppSubmissionErrorMessage,
} from 'components/Toast/BodyMessages';

export const TOAST_TEXT = {
  success: {
    title: "You're all set!",
    body: "We've got it from here; we'll be reaching out to your client within 24 hours. You can find a confirmation in your email.",
  },
  error: {
    title: 'Submission failed',
    body: <DropTicketErrorMessage />,
  },
  errorEAppSectionSubmission: {
    title: (section?: string) => `${section ?? 'Section'} failed to save`,
    body: <EAppSubmissionErrorMessage />,
  },
  errorEAppSubmission: {
    title: 'eApp submission failed',
    body: <EAppSubmissionErrorMessage />,
  },
  errorNYCarrier: {
    title: 'Unable to refer business in New York',
    body: <DropTicketErrorMessageNY />,
  },
};
